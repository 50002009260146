import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ToDashboardGuard implements CanLoad {

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private authService: AuthService
  ) {}

  async canLoad(): Promise<boolean> {
    let user = await firstValueFrom(this.auth.user);
    if(!user) {
      return true;
    } else {
      let user = await this.authService.getUser();
      if(user) {
        if(user.Rol.id == 2) {
          this.router.navigateByUrl('/admin');
        } else if(user.Rol.id == 1) {        
          this.router.navigateByUrl('/user');
        } else if(user.Rol.id == 3) {        
          this.router.navigateByUrl('/su');
        } else if(user.Rol.id == 4) {        
          this.router.navigateByUrl('/root');
        }
      }
      return false;
    }
  }
  
}
