// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  url: 'https://desaur-api.zurco.com.mx',
  API_KEY: 'desaur_api_key_xIVGmBnHx9oSLPnOE80SiRt782riV9Plm3rAmUviQyuuKEdFN',
  CRYPTO_KEY: 'desaur_crypto_os3PnFCz9we0JyTIZZb5WIlHQafILkACjSleoejS9vcUl2DcnD',
  firebase: {
    apiKey: "AIzaSyDNNVzQOKbxTkW18bZCtS6jldiVab6KPy4",
    authDomain: "desarrollo-urbano-tlalnepantla.firebaseapp.com",
    projectId: "desarrollo-urbano-tlalnepantla",
    storageBucket: "desarrollo-urbano-tlalnepantla.appspot.com",
    messagingSenderId: "3114447571",
    appId: "1:3114447571:web:5332a197e48546fac7da26",
    measurementId: "G-DMW49LDECN"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
