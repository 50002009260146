import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AbstractControl } from '@angular/forms';
import { FirebaseError } from '@firebase/util';
import { firstValueFrom } from 'rxjs';
import { UiService } from 'src/app/shared/services/ui.service';
import { environment } from 'src/environments/environment';
import { RegisterResponse } from '../interfaces/resgister';
import { LoginResponse } from '../interfaces/login_response';
import { DefautlResponse } from '../interfaces/default_response';
import { UserResponse } from '../interfaces/user_response';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private auth: AngularFireAuth,
    private toast: UiService
  ) { }

  
  private url: string = environment.url;
  private api_key: string = environment.API_KEY;
  private headers: HttpHeaders = new HttpHeaders({ 'x-api-key': this.api_key });

  async register(control: AbstractControl) {
    let status: boolean = false;
    this.toast.loading(true, 'Registro');
    const endPoint = `${this.url}/user`;
    try {
      let user = await this.auth.createUserWithEmailAndPassword(control.get('email')?.value, control.get('password')?.value);
      const body = {
        user: {
          uid: user.user?.uid,
          nickname: control.get('nickname')?.value,
          email: control.get('email')?.value,
          name: control.get('name')?.value,
          last_name: control.get('last_name')?.value,
          second_surname: control.get('second_surname')?.value,
          rol_id: 1
        },
        applicant: {
          nationality_id: control.get('nationality_id')?.value,
          telephone: control.get('telephone')?.value
        }
      };          
      let new_user = this.http.post<RegisterResponse>(endPoint, body, {headers: this.headers});
      let respUser = await firstValueFrom(new_user);
      console.log(respUser);
      this.toast.loading(false, 'Registro');
      if(respUser.ok) {
        this.toast.success('El usuario se registró exitosamente.');
        localStorage.setItem('token', `${respUser.token}`);
        status = true;
      } else {
        await this.auth.signOut();
        respUser.errors?.forEach(element => {
          this.toast.error(element);
        });
        status = false;
      }
      return status;
    } catch (err) {
      if (err instanceof FirebaseError) {        
        this.toast.loading(false, 'Registro');
        let error = (err as FirebaseError);
        switch (error.code) {
          case 'auth/email-already-in-use':
            this.toast.error('El correo ya está registrado, intenta con otro.');
          break;
          default:
            this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
          break;
        }
      } else {
        this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
      }
      return false;
    }
  }

  async login(control: AbstractControl) {
    this.toast.loading(true, 'Iniciando sesión');
    const endPoint = `${this.url}/user`;

    try {
      let user = await this.auth.signInWithEmailAndPassword(control.get('email')?.value, control.get('password')?.value);
      let loginResp = this.http.get<LoginResponse>(`${endPoint}/login/${user.user?.uid}`, {headers: this.headers});
      let resp = await firstValueFrom(loginResp);      
      this.toast.loading(false, 'Iniciando sesión');
      if(resp.user) {
        localStorage.setItem('token', `${resp.token}`);
        return resp;
      } else {
        this.toast.error('El correo introducido no completó el registro.');
        await this.auth.signOut();
        return false;
      }
    } catch (err) {
      if (err instanceof FirebaseError) { 
        this.toast.loading(false, 'Iniciando sesión');
        let error = (err as FirebaseError);
        switch (error.code) {
          case 'auth/user-not-found':
            this.toast.error('El correo introducido no está registrado.');
          break;
          case 'auth/wrong-password':
            this.toast.error('La contraseña es incorrecta.');
          break;
          case 'auth/too-many-requests':
            this.toast.error('El acceso a esta cuenta se ha desactivado temporalmente debido a muchos intentos fallidos de inicio de sesión. Puede restaurarlo inmediatamente restableciendo su contraseña o puede intentarlo de nuevo más tarde.');
          break;
          default:
            this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
          break;
        }
      } else {        
        await this.auth.signOut();
        this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
      }
      return false;
    }
  }

  async forgot(control: AbstractControl) {
    this.toast.loading(true, 'Iniciando sesión');
    const endPoint = `${this.url}/user`;

    try {
      await this.auth.sendPasswordResetEmail(control.get('email')?.value);     
      this.toast.loading(false, 'Iniciando sesión');
      this.toast.success('Se ha enviado un correo para restablecer tu contraseña.');
    } catch (err) {
      if (err instanceof FirebaseError) { 
        this.toast.loading(false, 'Iniciando sesión');
        let error = (err as FirebaseError);
        switch (error.code) {
          case 'auth/user-not-found':
            this.toast.error('El correo introducido no está registrado.');
          break;
          default:
            this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
          break;
        }
      } else {
        this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
      }
    }
  }

  async validToken() {
    let token = localStorage.getItem('token');
    let header = new HttpHeaders({ 'x-token': `${token}` });
    try {
      let res = await firstValueFrom(this.http.post<DefautlResponse>(`${this.url}/token/valid`, {}, { headers: header }));
      return res.ok;
    } catch (error) {
      return false;
    }
  }

  async getUser() {
    const endPoint = `${this.url}/user`;
    let token = localStorage.getItem('token');
    let header = new HttpHeaders({ 'x-token': `${token}` });
    let user = await firstValueFrom(this.auth.user);    

    try {
      let res = await firstValueFrom(this.http.get<UserResponse>(`${endPoint}/${user?.uid}`, { headers: header }));
      return res.user ?? false;
    } catch (err) {
      this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
      return false;
    }
  }

}
