import { Injectable } from '@angular/core';
import { CanLoad, CanActivate, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})

export class SignGuard implements CanLoad {

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private authService: AuthService
  ) {}

  async canLoad(): Promise<boolean> {
    let user = await firstValueFrom(this.auth.user);
    let token = await this.authService.validToken();
    if(user && token) {
      return true;
    } else {
      localStorage.clear();
      await this.auth.signOut();
      this.router.navigateByUrl('/auth/login');
      return false;
    }
  }
  
}
